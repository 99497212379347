import request from '@/utils/request'
import constant from '@/constant'

function statList(formData){
  return request.post(constant.serverUrl + "/business/workScheduleReport/statList", formData);
}

function exportXls(formData){
  return request.post(constant.serverUrl + "/business/workScheduleReport/exportXls", formData);
}

function batchUpdate(formData){
  return request.post(constant.serverUrl + "/business/workScheduleReport/batchUpdate", formData);
}

export default {
  statList,exportXls,batchUpdate
}